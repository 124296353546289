<template>
    <div class="ViewTutor">
        <div class="ViewTutorBox">
            <div class="header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: 'firmInfo' }">合作企业</el-breadcrumb-item>
                    <el-breadcrumb-item>企业导师</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="tutorList" v-loading="loading">
                <CardHeader :title="'企业导师'" :pt="0" :pb="28" />
                <div v-if="StaffList.Data.length>0">
                    <div class="list-item" v-for="(item, i) in StaffList.Data" :key="i" @click="$router.push('TutorDetail?id=' + item.Id)">
                        <div class="item-left">
                            <img class="img" v-real-img="item.Avatar" src="@/assets/images/student/default2.png" alt="" />
                        </div>
                        <div class="item-reight">
                            <div class="info">
                                <span class="name">{{ item.Name }}</span><span class="type">{{ item.Position ? item.Position : "" }} |
                                    {{ item.Speciality ? item.Speciality : "" }}</span>
                            </div>
                            <span class="txt" v-html="item.Description ? item.Description : ''">
                            </span>
                            <el-button type="text">查看详细</el-button>
                        </div>
                    </div>
                </div>
                <div v-else class="nodata">
                    <el-image style="width: 240px; height: 180px" :src="require('@/assets/images/nodata.png')" />
                    <span class="mt-18">暂无数据</span>
                </div>
                <div class="loading" v-if="StaffList.TotalCount > 10 && StaffList.Data.length >= 1">
                    <Pagination :total="totalCount" :limit="10" :layout="'prev, pager, next'" @pagination="areaPagination" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getPageList as getStaffList } from "@/api/enterprise/enterpriseStaff";
    import CardHeader from "@/components/Common/CardHeader.vue";
    import Pagination from "@/components/Pagination";
    export default {
        name: "ViewTutor",
        props: {},
        components: {
            Pagination,
            CardHeader
        },
        data() {
            return {
                loading: false,
                // 导师
                StaffList: [],
                queryStaffData: {
                    EnterpriseId: this.$route.query.id,
                    IsTeacher: true, // 是否导师
                    PageSize: 10,
                    PageIndex: 1
                }
            };
        },
        computed: {},
        watch: {},
        methods: {
            // 导师
            async getStaffList() {
                this.loading = true;
                try {
                    const { Result } = await getStaffList(this.queryStaffData);
                    this.StaffList = Result;
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            },
            areaPagination(val) {
                this.queryStaffData.PageIndex = val.page;
                this.search();
            }
        },
        created() {
            this.getStaffList();
        },
        mounted() {}
    };
</script>

<style scoped lang="scss">
    .ViewTutor {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 202px);
        background-color: #f5f5f5;
        box-sizing: border-box;

        .ViewTutorBox {
            width: 1100px;
            margin: 0 auto;

            .header {
                width: 100%;
                height: 44px;
                display: flex;
                align-items: center;
            }

            .tutorList {
                width: 100%;
                min-height: calc(100vh - 340px);
                height: 100%;
                background: #ffffff;
                border-radius: 8px;
                padding: 32px 32px 40px;
                margin-bottom: 40px;

                .list-item {
                    cursor: pointer;
                    width: 100%;
                    height: 104px;
                    margin-bottom: 42px;
                    display: flex;

                    .item-left {
                        width: 104px;
                        height: 104px;
                        margin-right: 24px;

                        .img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }

                        .noImg {
                            width: 100%;
                            height: 100%;
                            font-size: 14px;
                            line-height: 104px;
                            text-align: center;
                            border-radius: 50%;
                            background-color: #e5e6e7;
                        }
                    }

                    .item-reight {
                        width: 88%;

                        .info {
                            .name {
                                margin-right: 16px;
                                font-size: 20px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #333333;
                                line-height: 28px;
                            }

                            .type {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #999999;
                            }
                        }

                        .txt {
                            margin-top: 8px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                            line-height: 22px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                        }

                        /deep/ .el-button {
                            margin-top: 8px;
                            padding: 0 !important;
                        }
                    }
                }

                .loading {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 33px;
                }
            }
        }
    }

    .nodata {
        flex: 1;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        box-sizing: border-box;

        span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
        }
    }
</style>